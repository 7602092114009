<template>
	<div>
		<el-table :data="tableData" style="width: 100%">
			<el-table-column prop="id" label="ID"  width="100px">
			</el-table-column>
			<el-table-column prop="text_desciption" label="内容">
			</el-table-column>
			<el-table-column prop="timestamp" label="时间">
			</el-table-column>
			<el-table-column label="颜色">
				<template slot-scope='scope'>
					<span  class="myColor" :style="scope.row.current_color">***</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope='scope'>
					<el-button size='mini' type="primary" @click='editEvent(scope.row)'>编辑</el-button>
					<el-button size='mini' type='danger' @click='deleteEvent(scope.row.id)'>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-divider></el-divider>
		<h2 style="text-align: center;">添加时间线</h2>
		<el-form ref="form" :model="form" label-width="80px" size='mini' style='margin: 0 auto;width: 50%;'>
		  <el-form-item label="事件">
		    <el-input v-model="form.event" type="textarea" :rows="2" placeholder='事件'></el-input>
		  </el-form-item>
		  <el-form-item label="时间">
		    <el-input v-model="form.time" placeholder='格式:20180109000000'></el-input>
		  </el-form-item>
		  <el-form-item label="颜色">
		    <el-color-picker v-model="form.color"></el-color-picker>
		  </el-form-item>
		</el-form>
		<div class="btn-end">
			<el-button type="primary" @click='addTimeStamp'>添加事件<i class="el-icon-upload el-icon--right"></i></el-button>
			<el-button type="danger" icon="el-icon-delete" @click='clear'></el-button>
		</div>
		
		<el-dialog
		  title="编辑"
		  :visible.sync="dialogVisible"
		  width="30%"
		  :before-close="handleClose">
		  <el-form ref="form" :model="form2" label-width="60px" size='mini'>
		    <el-form-item label="事件">
		      <el-input v-model="form2.text_desciption" type="textarea" :rows="2" placeholder='事件'></el-input>
		    </el-form-item>
		    <el-form-item label="时间">
		      <el-input v-model="form2.timestamp" placeholder='格式:20180109000000'></el-input>
		    </el-form-item>
		    <el-form-item label="颜色">
		      <el-color-picker v-model="form2.current_color"></el-color-picker>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="startEditEvent">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import { getUserInfo } from '../../../server/api/store.js';
	import { adminMyTimestampInit,adminMyAddTimestamp,adminMyAddTimestampDelete,adminMyAddTimestampEdit } from '../../../server/api/index.js';
	export default {
		data() {
			return {
				tableData: [],
				form:{
					event:'',
					time:'',
					color:'#4CB3B7',
					account:''
				},
				form2:{},
				dialogVisible:false,
			}
		},
		methods:{
			async init(){
				let ref = await adminMyTimestampInit();
				if(ref.code===200){
					this.tableData = ref.data;
				}
			},
			clear(){
				this.form.event = '';
				this.form.time =  '';
			},
			handleClose(done) {
			    this.$confirm('确认关闭？')
			    .then(_ => {
			        done();
			    })
			    .catch(_ => {});
			},
			async addTimeStamp(){
				if(this.form.event.length===0||this.form.time.length===0) return this.$message.error('输入不能为空');
				// handle  form
				this.form.account = this.account;
				let ref = await adminMyAddTimestamp({form:this.form});
				if(ref.code===200){
					this.$message.success(ref.msg);
					this.clear();
					this.init();
				}else{
					this.$message.error(ref.msg);
				}
			},
			deleteEvent(id){
			    this.$confirm('确认删除？')
				.then(async _ => {
					let ref = await adminMyAddTimestampDelete({id,account:this.account});
					if(ref.code===200){
						this.$message.success(ref.msg);
						this.init();
					}else{
						this.$message.error(ref.msg);
					}
				    done();
				})
				.catch(_ => {});
			},
			editEvent(val){
				this.dialogVisible = true;
				val.current_color = val.current_color.slice(6);
				this.form2 = val;
			},
			async startEditEvent(){
				this.form2.account = this.account;
				let ref = await adminMyAddTimestampEdit({form2:this.form2});
				if(ref.code===200){
					this.$message.success(ref.msg);
					this.clear();
					this.init();
					this.dialogVisible = false;
				}else{
					this.$message.error(ref.msg);
				}
			}
		},
		mounted() {
			this.init();
		},
		computed: {
			account() {
				return getUserInfo().account;
			}
		}
	}
</script>

<style scoped="scoped">
.myColor{
	width: 50px; 
	height: 50px;
	font-size: 28px;
}
/deep/ .el-table th>.cell{
	text-align: center;
}
/deep/.el-table .cell{
	text-align: center;
	font-size: 12px;
	font-weight: 400;
}
/deep/.el-table td, .el-table th.is-leaf {
    border-bottom: 1px solid #EBEEF5;
}
/deep/.el-table th {
    background-color:#f5f7fa!important;
}
.btn-end{
	text-align:center;
	padding-bottom: 20px;
}
</style>
