<template>
	<div>我是评论管理</div>
</template>

<script>
	export default{
		
	}
</script>

<style scoped="scoped">
</style>
